define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/topic-footer-main-buttons-before-create/support-tickets", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.support_tickets_enabled}}
  {{#if this.topic.isPrivateMessage}}
  {{#unless this.topic.closed}}
  {{d-button class="standard"
               title="support_tickets.checkout_ticket"
               label="support_tickets.checkout_ticket"
               icon="shopping-cart"
               action=this.topic.createTicket
               actionParam=this.topic
               disabled=this.topic.support_ticket_created}}
  {{/unless}}
  {{/if}}
  {{/if}}
  
  */
  {
    "id": "yuwmOqoH",
    "block": "[[[41,[30,0,[\"siteSettings\",\"support_tickets_enabled\"]],[[[41,[30,0,[\"topic\",\"isPrivateMessage\"]],[[[41,[51,[30,0,[\"topic\",\"closed\"]]],[[[1,[28,[35,2],null,[[\"class\",\"title\",\"label\",\"icon\",\"action\",\"actionParam\",\"disabled\"],[\"standard\",\"support_tickets.checkout_ticket\",\"support_tickets.checkout_ticket\",\"shopping-cart\",[30,0,[\"topic\",\"createTicket\"]],[30,0,[\"topic\"]],[30,0,[\"topic\",\"support_ticket_created\"]]]]]],[1,\"\\n\"]],[]],null]],[]],null]],[]],null]],[],false,[\"if\",\"unless\",\"d-button\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/topic-footer-main-buttons-before-create/support-tickets.hbs",
    "isStrictMode": false
  });
});