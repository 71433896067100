define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-card-metadata/xswapd-rank", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.args.user.swapd_rank_can_see}}
    <a href="/u/{{this.args.user.username}}/activity/report">
      <span class="sr__green">
        {{i18n "support_tickets.usercard.total_sales"}}
        {{#if this.args.user.swapd_rank.sales}}
          {{swapd-amount this.args.user.swapd_rank.sales}}
        {{else}}
          N/A
        {{/if}}
      </span>
  
      <span class="sr__red">
        {{i18n "support_tickets.usercard.total_purchases"}}
        {{#if this.args.user.swapd_rank.purchases}}
          {{swapd-amount this.args.user.swapd_rank.purchases}}
        {{else}}
          N/A
        {{/if}}
      </span>
  
      <span class="sr__black">
        {{i18n "support_tickets.usercard.rank"}}
        {{#if this.args.user.swapd_rank.sales_rank}}
          {{swapd-rank this.args.user.swapd_rank.sales_rank}}
        {{else}}
          N/A
        {{/if}}
      </span>
    </a>
  {{/if}}
  
  */
  {
    "id": "xc8IbK4J",
    "block": "[[[41,[30,0,[\"args\",\"user\",\"swapd_rank_can_see\"]],[[[1,\"  \"],[10,3],[15,6,[29,[\"/u/\",[30,0,[\"args\",\"user\",\"username\"]],\"/activity/report\"]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"sr__green\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"support_tickets.usercard.total_sales\"],null]],[1,\"\\n\"],[41,[30,0,[\"args\",\"user\",\"swapd_rank\",\"sales\"]],[[[1,\"        \"],[1,[28,[35,2],[[30,0,[\"args\",\"user\",\"swapd_rank\",\"sales\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        N/A\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"sr__red\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"support_tickets.usercard.total_purchases\"],null]],[1,\"\\n\"],[41,[30,0,[\"args\",\"user\",\"swapd_rank\",\"purchases\"]],[[[1,\"        \"],[1,[28,[35,2],[[30,0,[\"args\",\"user\",\"swapd_rank\",\"purchases\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        N/A\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,1],[14,0,\"sr__black\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"support_tickets.usercard.rank\"],null]],[1,\"\\n\"],[41,[30,0,[\"args\",\"user\",\"swapd_rank\",\"sales_rank\"]],[[[1,\"        \"],[1,[28,[35,3],[[30,0,[\"args\",\"user\",\"swapd_rank\",\"sales_rank\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        N/A\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"swapd-amount\",\"swapd-rank\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-card-metadata/xswapd-rank.hbs",
    "isStrictMode": false
  });
});