define("discourse/plugins/swapd-support-tickets/discourse/templates/components/step-advanced", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#start-step
    title=this.title
    helpMsg=this.helpMsg
    next=(action "next")
    nextDisabled=this.nextDisabled
    nextLabel=this.nextLabel
    loading=this.loading
    step=this.step}}
  
    <div class="start__step-content">
      {{#if this.isText}}
        <Input
          @value={{this.value}}
          class="start__input start__text-input"
        />
      {{/if}}
  
      {{#if this.isEditor}}
        {{d-editor value=this.value}}
      {{/if}}
  
      {{#if this.isSelect}}
        <select class="start__select {{this.stepName}}" onchange={{action (mut this.value) value="target.value"}}>
          <option value="" selected={{isblank this.value}} disabled="">{{i18n "support_tickets.start.choose"}}</option>
  
          {{#each this.options as |opt|}}
            <option value={{opt.value}} selected={{eq this.value opt.value}}>
              {{i18n opt.display}}
            </option>
          {{/each}}
        </select>
      {{/if}}
  
      {{#if this.isTerms}}
        <label class="checkbox-label">
          {{input
            type="checkbox"
            checked=this.value
          }}
  
          {{i18n "support_tickets.start.accept_terms"}}
        </label>
      {{/if}}
    </div>
  {{/start-step}}
  */
  {
    "id": "DFdwn4/R",
    "block": "[[[6,[39,0],null,[[\"title\",\"helpMsg\",\"next\",\"nextDisabled\",\"nextLabel\",\"loading\",\"step\"],[[30,0,[\"title\"]],[30,0,[\"helpMsg\"]],[28,[37,1],[[30,0],\"next\"],null],[30,0,[\"nextDisabled\"]],[30,0,[\"nextLabel\"]],[30,0,[\"loading\"]],[30,0,[\"step\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"start__step-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"isText\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"start__input start__text-input\"]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isEditor\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"value\"],[[30,0,[\"value\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isSelect\"]],[[[1,\"      \"],[10,\"select\"],[15,0,[29,[\"start__select \",[30,0,[\"stepName\"]]]]],[15,\"onchange\",[28,[37,1],[[30,0],[28,[37,5],[[30,0,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n        \"],[10,\"option\"],[14,2,\"\"],[15,\"selected\",[28,[37,6],[[30,0,[\"value\"]]],null]],[14,\"disabled\",\"\"],[12],[1,[28,[35,7],[\"support_tickets.start.choose\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,0,[\"options\"]]],null]],null],null,[[[1,\"          \"],[10,\"option\"],[15,2,[30,1,[\"value\"]]],[15,\"selected\",[28,[37,10],[[30,0,[\"value\"]],[30,1,[\"value\"]]],null]],[12],[1,\"\\n            \"],[1,[28,[35,7],[[30,1,[\"display\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"isTerms\"]],[[[1,\"      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"value\"]]]]]],[1,\"\\n\\n        \"],[1,[28,[35,7],[\"support_tickets.start.accept_terms\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"opt\"],false,[\"start-step\",\"action\",\"if\",\"input\",\"d-editor\",\"mut\",\"isblank\",\"i18n\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/step-advanced.hbs",
    "isStrictMode": false
  });
});