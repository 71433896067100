define("discourse/plugins/swapd-support-tickets/discourse/templates/start", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-section pageClass="start"}}
  
  <div class="container">
    {{component this.componentName
      model=this.model
      step=this.step
      topic=this.topic
      showLogin=(route-action "showLogin")
      showCreateAccount=(route-action "showCreateAccount")}}
  </div>
  
  */
  {
    "id": "hI6efxnD",
    "block": "[[[1,[28,[35,0],null,[[\"pageClass\"],[\"start\"]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[46,[30,0,[\"componentName\"]],null,[[\"model\",\"step\",\"topic\",\"showLogin\",\"showCreateAccount\"],[[30,0,[\"model\"]],[30,0,[\"step\"]],[30,0,[\"topic\"]],[28,[37,2],[\"showLogin\"],null],[28,[37,2],[\"showCreateAccount\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"d-section\",\"component\",\"route-action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/start.hbs",
    "isStrictMode": false
  });
});