define("discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-activity-bottom/swapd-rank-report", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.swapd_rank_can_see}}
    <LinkTo @route="userActivity.report">
      <img class="sr__trophy" src="/plugins/swapd-support-tickets/trophy.png">
      {{i18n "support_tickets.user_report.report"}}
    </LinkTo>
  {{/if}}
  
  */
  {
    "id": "DQ6PWGh5",
    "block": "[[[41,[30,0,[\"model\",\"swapd_rank_can_see\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.report\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"img\"],[14,0,\"sr__trophy\"],[14,\"src\",\"/plugins/swapd-support-tickets/trophy.png\"],[12],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"support_tickets.user_report.report\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"i18n\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/connectors/user-activity-bottom/swapd-rank-report.hbs",
    "isStrictMode": false
  });
});