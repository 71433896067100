define("discourse/plugins/swapd-support-tickets/discourse/templates/components/step-one", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#start-step
    title=this.title
    subTitle=""
    next=(action "next")
    nextDisabled=this.nextDisabled
    nextLabel="support_tickets.start.next"
    step=this.step}}
  
    <div class="start__step-content">
      <select class="start__select" onchange={{action (mut this.model.seller_or_buyer) value="target.value"}}>
        <option selected="" value="" disabled="">{{i18n "support_tickets.start.choose"}}</option>
        <option value="seller" selected={{eq this.model.seller_or_buyer "seller"}}>Seller</option>
        <option value="buyer" selected={{eq this.model.seller_or_buyer "buyer"}}>Buyer</option>
      </select>
    </div>
  
  {{/start-step}}
  
  */
  {
    "id": "FsDmpvXe",
    "block": "[[[6,[39,0],null,[[\"title\",\"subTitle\",\"next\",\"nextDisabled\",\"nextLabel\",\"step\"],[[30,0,[\"title\"]],\"\",[28,[37,1],[[30,0],\"next\"],null],[30,0,[\"nextDisabled\"]],\"support_tickets.start.next\",[30,0,[\"step\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"start__step-content\"],[12],[1,\"\\n    \"],[10,\"select\"],[14,0,\"start__select\"],[15,\"onchange\",[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"model\",\"seller_or_buyer\"]]],null]],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n      \"],[10,\"option\"],[14,\"selected\",\"\"],[14,2,\"\"],[14,\"disabled\",\"\"],[12],[1,[28,[35,3],[\"support_tickets.start.choose\"],null]],[13],[1,\"\\n      \"],[10,\"option\"],[14,2,\"seller\"],[15,\"selected\",[28,[37,4],[[30,0,[\"model\",\"seller_or_buyer\"]],\"seller\"],null]],[12],[1,\"Seller\"],[13],[1,\"\\n      \"],[10,\"option\"],[14,2,\"buyer\"],[15,\"selected\",[28,[37,4],[[30,0,[\"model\",\"seller_or_buyer\"]],\"buyer\"],null]],[12],[1,\"Buyer\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]]]]]],[],false,[\"start-step\",\"action\",\"mut\",\"i18n\",\"eq\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/step-one.hbs",
    "isStrictMode": false
  });
});