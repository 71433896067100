define("discourse/plugins/swapd-support-tickets/discourse/templates/components/ticket-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.selectedTag}}
    <div class="ticket-status-container ticket-status-{{this.selectedTag.tag}}">
      Status: <strong>{{this.selectedTag.status_text}}</strong> -
      <a href={{this.selectedTag.topic_link}}>What's this?</a>
    </div>
  {{/if}}
  
  */
  {
    "id": "QHgvfWp2",
    "block": "[[[41,[30,0,[\"selectedTag\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ticket-status-container ticket-status-\",[30,0,[\"selectedTag\",\"tag\"]]]]],[12],[1,\"\\n    Status: \"],[10,\"strong\"],[12],[1,[30,0,[\"selectedTag\",\"status_text\"]]],[13],[1,\" -\\n    \"],[10,3],[15,6,[30,0,[\"selectedTag\",\"topic_link\"]]],[12],[1,\"What's this?\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/templates/components/ticket-status.hbs",
    "isStrictMode": false
  });
});